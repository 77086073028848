import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  MANAGE_CLASS,
  MANAGE_CLASS_API_URL,
  GET_ALL_MANAGE_CLASS_API_URL,
  ADD_CLASS_COURSE_API_URL,
  GET_TAGGED_CLASS_COURSE_TEACHER_API_URL,
  ADD_CLASS_STUDENT_API_URL,
  GET_OTHER_CLASS_API_URL,
  GET_GRADE_STUDENT_API_URL,
  GET_OTHER_CLASS_STUDENT_API_URL,
  DELETE_CLASS_STUDENT_API_URL,
  DELETE_CLASS_COURSE_API_URL,
  CLASS_COURSE_FILTER_API_URL,
  CLASS_STUDENT_FILTER_API_URL,
  SEND_EMAIL,
  SAVE_GOOGLE_TOKEN,
  GET_CLASS_COURSE_DATA_API_URL,
  GET_CLASS_COURSE_API_URL,
  SAVE_LESSON_TOPIC,
  ADD_CLASS_COURSE_WEIGHTAGE_API_URL,
  DELETE_CLASS_COURSE_Teacher_API_URL
} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageClassErrors: {},
  ClassCourseErrors: {},
  ClassStudentErrors: {},
  ConversationErrors: {},
  permission: permission?.manage_class ?? [],
  ManageClassShowMessage: null,
  ClassCourseShowMessage: null,
  ClassStudentShowMessage: null,
  ConversationShowMessage: null,
  ManageClassResult: [],
  ConversationButtonSpinner: false,
  ClassCourseButtonSpinner: false,
  ManageClassButtonSpinner: false,
  ClassStudentButtonSpinner: false,
  ManageClassTableLoading: true,
  ManageClassAddDrawer: false,
  ClassOverviewDrawer: true,
  ConversationAddDrawer: false,
  AttendanceDrawer: false,
  classCourseDrawer: false,
  ClassCourseWeightageButtonSpinner: false,
  ClassCourseWeightageDrawer: false,
  classCourseViewDrawer: false,
  classStudentDrawer: false,
  ManageClassButtonAndModelLabel: setLocale('classes.add'),
  AddClassCourseTitle: setLocale("classes.addClassCourse"),
  AddClassStudentTitle: setLocale("classes.addClassStudents"),
  ConversationButtonAndModelLabel: setLocale("classes.conversation"),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    schoolYear: null,
    gradeLevel: null,
    searchString: null,
    organization_id: null
  },
  ManageClassEditData: {},
  ClassCourseEditData: [],
  ClassCoursesData: [],
  ClassCourses: [],
  course_schedule: [],
  ClassStudentsData: [],
  ViewManageClassData: {
    'class_courses_count': null,
    'class_student_count': null,
    'enc_id': null,
    'start_date': null,
    'end_date': null,
    'grade_level': null,
    'id': null,
    'is_same_room': null,
    'label': null,
    'name': null,
    'organization_grade_level_id': null,
    'organization_id': null,
    'school_room': null,
    'school_room_id': null,
    'school_year': null,
    'school_year_id': null,
    'totalDayLefts': null,
    'value': null,
  },
  ViewManageClassLoader: true,
  taggedClassCourseTeachers: [],

  OtherClassesData: [],
  gradeStudents: [],
  OtherClassStudents: [],
  classCourseSchedule: [],
  selectedRowKeys: [],
  classCourseScheduleDrawer: true,
  classCourseLessonDrawer: false,
  classCourseGradeDrawer: false,
  classCourseAssignmentDrawer: false,
  classCourseSearchString: '',
  classCourseLoader: false,
  classStudentSearchString: '',
  classStudentLoader: false,
  conversationAttachment: null,

  selectedLessonPlan: null,
  openTopicModal: false
};

export const saveLessonTopic = createAsyncThunk("saveLessonTopic", async (data) => {
  try {
    return await CommonService.postData(data, SAVE_LESSON_TOPIC);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const createManageClass = createAsyncThunk(
  "createManageClass",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MANAGE_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageClass = createAsyncThunk(
  "getManageClass",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageClass = createAsyncThunk(
  "viewManageClass",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageClass = createAsyncThunk(
  "deleteManageClass",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTaggedClassCourseTeachers = createAsyncThunk(
  "getTaggedClassCourseTeachers",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_TAGGED_CLASS_COURSE_TEACHER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const get_class_courses = createAsyncThunk(
  "get_class_courses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_CLASS_COURSE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClassCourseData = createAsyncThunk(
  "getClassCourseData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_CLASS_COURSE_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const createClassCourse = createAsyncThunk(
  "createClassCourse",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADD_CLASS_COURSE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const updateClassCourseWeightage = createAsyncThunk(
  "updateClassCourseWeightage",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADD_CLASS_COURSE_WEIGHTAGE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const addClassStudent = createAsyncThunk(
  "addClassStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADD_CLASS_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOtherClasses = createAsyncThunk(
  "getOtherClasses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_OTHER_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeStudents = createAsyncThunk('getGradeStudents', async (data) => {

  try {
    const response = await CommonService.getAllPost(data, GET_GRADE_STUDENT_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }

});

export const getOtherClassStudent = createAsyncThunk('getOtherClassStudent', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, GET_OTHER_CLASS_STUDENT_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const deleteClassStudent = createAsyncThunk('deleteClassStudent', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, DELETE_CLASS_STUDENT_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const deleteClassCourse = createAsyncThunk('deleteClassCourse', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, DELETE_CLASS_COURSE_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const classCourseFilter = createAsyncThunk('classCourseFilter', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, CLASS_COURSE_FILTER_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const classStudentFilter = createAsyncThunk('classStudentFilter', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, CLASS_STUDENT_FILTER_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const calenderEvent = createAsyncThunk('calenderEvent', async (data) => {
  //    console.log('you are in slice',data)
  return data;
});

export const saveGoogleToken = createAsyncThunk(
  "saveGoogleToken",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_GOOGLE_TOKEN);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const sendEmail = createAsyncThunk(
  "sendEmail",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SEND_EMAIL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClassCourseTeacher = createAsyncThunk('deleteClassCourseTeacher', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, DELETE_CLASS_COURSE_Teacher_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageManageClassSlice = createSlice({
  name: MANAGE_CLASS,
  initialState,
  reducers: {
    setSelectedLessonPlan: (state, action) => {
      state.selectedLessonPlan = action.payload.data;
      state.openTopicModal = action.payload.status;
    },
    setClassCourseSchedule: (state, action) => {
      state.classCourseSchedule = action.payload;
    },
    ManageClassAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.ManageClassButtonSpinner = false;
      }
      state.ManageClassErrors = {};
      state.ManageClassAddDrawer = action.payload;
      state.ManageClassEditData = [];
      state.ManageClassButtonAndModelLabel = setLocale('classes.add');
    },
    ManageClassEditWithDrawerStatus: (state, action) => {
      state.ManageClassErrors = {};
      state.ManageClassAddDrawer = true;
      state.ManageClassEditData = action.payload;
      state.ManageClassButtonAndModelLabel = setLocale('classes.edit');
    },
    ManageConversationDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ConversationButtonSpinner = false;
      }
      state.ConversationErrors = {};
      state.conversationAttachment = null;
      state.ConversationAddDrawer = action.payload.status;
      state.ManageClassEditData = action.payload.data;
      state.ConversationButtonAndModelLabel = setLocale('classes.conversation');
    },
    ClassOverviewDrawerStatus: (state, action) => {
      state.ClassOverviewDrawer = action.payload;
    },
    AttendanceDrawerStatus: (state, action) => {
      state.AttendanceDrawer = action.payload;
    },
    ClassCourseDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseButtonSpinner = false;
      }
      state.ClassCourseErrors = {}
      state.classCourseDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
      state.AddClassCourseTitle = action.payload.data.length > 0 ? setLocale("classes.updateClassCourse") : setLocale("classes.addClassCourse");
    },
    ClassCourseWeightageDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassCourseWeightageButtonSpinner = false;
      }
      state.ClassCourseWeightageDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload.data;
    },
    ClassStudentDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClassStudentButtonSpinner = false;
      }
      state.classStudentDrawer = action.payload.status;
    },
    ClassCourseViewDrawerStatus: (state, action) => {
      state.classCourseScheduleDrawer = action.payload.status;
      state.classCourseViewDrawer = action.payload.status;
      state.ClassCourseEditData = action.payload?.data ?? [];
      state.classCourseSchedule = action.payload?.data?.course_schedule ?? [];
    },
    resetStates: (state, action) => {
      state.ManageClassAddDrawer = false;
      state.ClassOverviewDrawer = true;
      state.ConversationAddDrawer = false;
      state.AttendanceDrawer = false;
      state.classCourseDrawer = false;
      state.classCourseViewDrawer = false;
      state.classStudentDrawer = false;
    },
    ClassCourseScheduleDrawerStatus: (state, action) => {
      state.classCourseScheduleDrawer = action.payload.status;
      state.classCourseSchedule = action.payload.data;

      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseLessonDrawerStatus: (state, action) => {
      state.classCourseLessonDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseGradeDrawerStatus: (state, action) => {
      state.classCourseGradeDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseAssignmentDrawerStatus: (state, action) => {
      state.classCourseAssignmentDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
    },
    updateClassCourseString: (state, action) => {
      state.classCourseSearchString = action.payload;
    },
    updateClassStudentString: (state, action) => {
      state.classStudentSearchString = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    updateSortFilters: (state, action) => {
      state.filter.searchString = action.payload?.searchString;
      state.filter.schoolYear = action.payload?.schoolYear;
      state.filter.gradeLevel = action.payload?.gradeLevel;
      state.filter.organization_id = action.payload?.organization_id;
    },
    setConversationAttachment: (state, action) => {
      state.conversationAttachment = action.payload
    },
    setTeacherCourses: (state, action) => {
      state.ClassCoursesData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveLessonTopic.pending, (state, action) => {
        state.ClassCourseButtonSpinner = true
      }).addCase(saveLessonTopic.fulfilled, (state, action) => {
        state.ClassCourseButtonSpinner = false
      }).addCase(saveLessonTopic.rejected, (state, action) => {
        state.ClassCourseButtonSpinner = false
      })
      .addCase(sendEmail.pending, (state, action) => {
        state.ConversationButtonSpinner = true;
      }).addCase(sendEmail.fulfilled, (state, action) => {
        state.ConversationButtonSpinner = false;
        state.ConversationErrors = {}
      }).addCase(sendEmail.rejected, (state, action) => {
        state.ConversationShowMessage = true;
        state.ConversationButtonSpinner = false;
        state.ConversationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(calenderEvent.pending, (state, action) => {
      }).addCase(calenderEvent.fulfilled, (state, action) => {
      }).addCase(calenderEvent.rejected, (state, action) => {
      })
      .addCase(createManageClass.pending, (state, action) => {
        state.ManageClassButtonSpinner = true;
      }).addCase(createManageClass.fulfilled, (state, action) => {
        state.ManageClassButtonSpinner = false;
        state.ManageClassErrors = {}
      }).addCase(createManageClass.rejected, (state, action) => {
        state.ManageClassShowMessage = true;
        state.ManageClassButtonSpinner = false;
        state.ManageClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(saveGoogleToken.rejected, (state, action) => {
      })
      .addCase(createClassCourse.pending, (state, action) => {
        state.ClassCourseButtonSpinner = true;
      }).addCase(createClassCourse.fulfilled, (state, action) => {
        state.ClassCourseButtonSpinner = false;
        state.ClassCourseErrors = {}
        state.ClassCoursesData = action.payload?.data?.class_courses;

        state.ViewManageClassData =
        {
          ...state.ViewManageClassData,
          'class_courses_count': action.payload?.data?.class_courses_count
        };
      }).addCase(createClassCourse.rejected, (state, action) => {
        state.ClassCourseShowMessage = true;
        state.ClassCourseButtonSpinner = false;
        state.ClassCourseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(updateClassCourseWeightage.pending, (state, action) => {
        state.ClassCourseWeightageButtonSpinner = true;
      }).addCase(updateClassCourseWeightage.fulfilled, (state, action) => {
        state.ClassCourseWeightageButtonSpinner = false;
        state.ClassCourseErrors = {}
        state.ClassCoursesData = action.payload?.data?.class_courses;

        state.ViewManageClassData =
        {
          ...state.ViewManageClassData,
          'class_courses_count': action.payload?.data?.class_courses_count
        };
      }).addCase(updateClassCourseWeightage.rejected, (state, action) => {
        state.ClassCourseShowMessage = true;
        state.ClassCourseWeightageButtonSpinner = false;
        state.ClassCourseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(classCourseFilter.pending, (state, action) => {
        state.classCourseLoader = true;
      }).addCase(classCourseFilter.fulfilled, (state, action) => {
        state.classCourseLoader = false;
        state.ClassCoursesData = action.payload;
      }).addCase(classCourseFilter.rejected, (state, action) => {
        state.classCourseLoader = false;
      })


      .addCase(classStudentFilter.pending, (state, action) => {
        state.classStudentLoader = true;
      }).addCase(classStudentFilter.fulfilled, (state, action) => {
        state.classStudentLoader = false;
        state.ClassStudentsData = action.payload;
      }).addCase(classStudentFilter.rejected, (state, action) => {
        state.classStudentLoader = false;
      })

      .addCase(addClassStudent.pending, (state, action) => {
        // state.ClassStudentButtonSpinner = true;
      }).addCase(addClassStudent.fulfilled, (state, action) => {
        state.ClassStudentButtonSpinner = false;
        state.ClassStudentErrors = {}
        state.ClassStudentsData = action.payload?.data?.class_student;
        state.ViewManageClassData =
        {
          ...state.ViewManageClassData,
          'class_student_count': action.payload?.data?.class_student_count,
        };
      }).addCase(addClassStudent.rejected, (state, action) => {
        state.ClassStudentShowMessage = true;
        state.ClassStudentButtonSpinner = false;
        state.ClassStudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(deleteClassStudent.pending, (state, action) => {
      }).addCase(deleteClassStudent.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassStudentsData = action.payload?.data?.class_student;
          state.ViewManageClassData =
          {
            ...state.ViewManageClassData,
            'class_student_count': action.payload?.data?.class_student_count
          };
        }
      }).addCase(deleteClassStudent.rejected, (state, action) => {
      })

      .addCase(deleteClassCourse.pending, (state, action) => {
      }).addCase(deleteClassCourse.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassCoursesData = action.payload?.data?.class_courses;
          state.ViewManageClassData =
          {
            ...state.ViewManageClassData,
            'class_courses_count': action.payload?.data?.class_courses_count,
          };
        }
      }).addCase(deleteClassCourse.rejected, (state, action) => {
      })

      .addCase(deleteClassCourseTeacher.pending, (state, action) => {
      }).addCase(deleteClassCourseTeacher.fulfilled, (state, action) => {
        if (action.payload.status !== false) {
          state.ClassCoursesData = action.payload?.data?.class_courses;
          state.ViewManageClassData =
          {
            ...state.ViewManageClassData,
            'class_courses_count': action.payload?.data?.class_courses_count,
          };
        }
      }).addCase(deleteClassCourseTeacher.rejected, (state, action) => {
      })


      .addCase(getOtherClasses.pending, (state, action) => {
      }).addCase(getOtherClasses.fulfilled, (state, action) => {
        state.OtherClassesData = action.payload;
      }).addCase(getOtherClasses.rejected, (state, action) => {
      })

      .addCase(getGradeStudents.pending, (state, action) => {
      }).addCase(getGradeStudents.fulfilled, (state, action) => {
        state.gradeStudents = action.payload;
      }).addCase(getGradeStudents.rejected, (state, action) => {
      })

      .addCase(getOtherClassStudent.pending, (state, action) => {
      }).addCase(getOtherClassStudent.fulfilled, (state, action) => {
        state.OtherClassStudents = action.payload;
      }).addCase(getOtherClassStudent.rejected, (state, action) => {
      })

      .addCase(getManageClass.pending, (state, action) => {
        state.ManageClassButtonSpinner = true;
        state.ManageClassTableLoading = true;
      }).addCase(getManageClass.fulfilled, (state, action) => {
        state.ManageClassButtonSpinner = false;
        state.ManageClassTableLoading = false;
        state.ManageClassResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getManageClass.rejected, (state, action) => {
        state.ManageClassShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageClassButtonSpinner = false;
        state.ManageClassTableLoading = false;
        state.ManageClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getTaggedClassCourseTeachers.pending, (state, action) => {
      }).addCase(getTaggedClassCourseTeachers.fulfilled, (state, action) => {
        state.taggedClassCourseTeachers = action.payload;
      }).addCase(getTaggedClassCourseTeachers.rejected, (state, action) => {
      })

      .addCase(get_class_courses.pending, (state, action) => {
      }).addCase(get_class_courses.fulfilled, (state, action) => {
        state.ClassCourses = action.payload;
      }).addCase(get_class_courses.rejected, (state, action) => {
      })

      .addCase(getClassCourseData.pending, (state, action) => {
      }).addCase(getClassCourseData.fulfilled, (state, action) => {
        state.course_schedule = action?.payload ? action?.payload[0]?.course_schedule : [];
      }).addCase(getClassCourseData.rejected, (state, action) => {
      })

      .addCase(viewManageClass.pending, (state, action) => {
        state.ViewManageClassLoader = true;
        state.ViewManageClassData = [];
      }).addCase(viewManageClass.fulfilled, (state, action) => {
        state.ViewManageClassLoader = false;
        state.ClassStudentsData = action.payload?.class_student;
        state.ClassCoursesData = action.payload?.class_courses;
        // state.ViewManageClassData = action.payload
        state.ViewManageClassData =
        {
          ...state.ViewManageClassData,
          'class_courses_count': action.payload?.class_courses_count,
          'class_student_count': action.payload?.class_student_count,
          'enc_id': action.payload?.enc_id,
          'end_date': action.payload?.end_date,
          'grade_level': action.payload?.grade_level,
          'id': action.payload?.id,
          'is_same_room': action.payload?.is_same_room,
          'label': action.payload?.label,
          'name': action.payload?.name,
          'organization_grade_level_id': action.payload?.organization_grade_level_id,
          'organization_id': action.payload?.organization_id,
          'school_room': action.payload?.school_room,
          'school_room_id': action.payload?.school_room_id,
          'school_year': action.payload?.school_year,
          'school_year_id': action.payload?.school_year_id,
          'start_date': action.payload?.start_date,
          'totalDayLefts': action.payload?.totalDayLefts,
          'value': action.payload?.value,
        };
      }).addCase(viewManageClass.rejected, (state, action) => {
        state.ViewManageClassLoader = false;
        state.ViewManageClassData = [];
      })

      .addCase(deleteManageClass.pending, (state, action) => {
      }).addCase(deleteManageClass.fulfilled, (state, action) => {
      }).addCase(deleteManageClass.rejected, (state, action) => {
      });


  },
});

export const {
  updateClassCourseString,
  updateClassStudentString,
  ClassCourseAssignmentDrawerStatus,
  ClassCourseGradeDrawerStatus,
  ClassCourseLessonDrawerStatus,
  ClassCourseScheduleDrawerStatus,
  ClassCourseViewDrawerStatus,
  ClassStudentDrawerStatus,
  ClassCourseDrawerStatus,
  AttendanceDrawerStatus,
  ClassOverviewDrawerStatus,
  ManageClassAddDrawerStatus,
  ClassCourseWeightageDrawerStatus,
  ManageClassEditWithDrawerStatus,
  updateSortFilters,
  setSelectedRowKeys,
  ManageConversationDrawerStatus,
  setConversationAttachment,
  setTeacherCourses,
  resetStates,
  setClassCourseSchedule,
  setSelectedLessonPlan
} = manageManageClassSlice.actions;

export default manageManageClassSlice.reducer;
